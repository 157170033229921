@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  background-color: var(--colorGrey50);
  position: relative;
  width: 100%;
}

.filters {
  & > div:nth-child(1) {
    margin-bottom: 8px;
    height: auto;
    /* width: 33.33% !important;
    flex: 0 0 33.33% !important; */
    order: 2;
    /* margin: 0 !important; */
    margin: 0 auto 8px;
    max-width:unset;
    @media(max-width:1200px){
      max-width: 490px;
    }
    @media(max-width:1199px){
      /* margin-left:0;
      margin-right:0; */
      /* width: 50%;
      flex: 0 0 50%; */
      max-width: 420px;
    }
   @media(max-width:991px){
    max-width:unset;
   }
  }
  & > form{
    order:1;
    /* width: 33.33% !important;
    flex: 0 0 33.33% !important; */
  }
  & > div:nth-child(2),& > div:nth-child(3){
    /* width: 33.33% !important;
    flex: 0 0 33.33% !important; */
    order:3;
    
  }
  & > div:nth-child(2){
    margin-right:12px;
    @media(max-width:1199px){
      margin-left:12px;
    }
    @media(max-width:991px){
      margin:0;
    }
  }
}
